import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import showdown from 'showdown'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'

const BasicPageWrapper = styled.div`
  width: 100%;
  max-width: var(--bp-xlarge);
  padding: 2rem 1rem;
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (min-width: 1024px) {
    padding: 4rem 0;
  }
`

const BasicPageBody = styled.div`
  display: block;
  margin-bottom: 2rem;
  font-size: var(--fs-regular);
  font-family: 'Open Sans', sans-serif;
  width: 100%;
  max-width: 80%;
  margin-right: auto;
  margin-left: auto;
  h2 {
    font-size: var(--fs-medium);
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
  p {
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: var(--fs-default);
  }
`

const converter = new showdown.Converter()

const BasicPage = ({ data }) => {
  const basicPageContent = data.markdownRemark.frontmatter

  return (
    <Layout>
      <PageHero title={basicPageContent.hero_title} body={basicPageContent.subtitle} image={basicPageContent.hero_image}/>
      <BasicPageWrapper>
        <BasicPageBody>
          <div
            dangerouslySetInnerHTML={{
              __html: converter.makeHtml(
                basicPageContent.basic_page_body
              ),
            }}
          />
        </BasicPageBody>
      </BasicPageWrapper>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: {basic_page_slug: {eq: $slug}}) {
      frontmatter {
        basic_page_body
        basic_page_title
        hero_image
        hero_title
      }
    }
  }
`

export default BasicPage
